var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('navbar',{attrs:{"title":_vm.$t('dashboard.presence')}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"container-fluid"},[_c('head-menu-filter',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.$t('global.filter')}}),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","initial":_vm.filters.termin,"show-icon":true,"tabindex":1,"field":"termin"},on:{"updatefull":_vm.setFilterDate}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.appointmentdate")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","initial":_vm.filters.von,"show-icon":true,"tabindex":1,"field":"von"},on:{"updatefull":_vm.setFilterDate}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.fromdate")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('date-picker',{attrs:{"date-format":"dd.mm.yy","show-icon":true,"initial":_vm.filters.bis,"tabindex":2,"field":"bis"},on:{"updatefull":_vm.setFilterDate}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.untildate")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                        _vm.filters['dozentenbuchung.veranstaltung.kuerzel']
                      ),expression:"\n                        filters['dozentenbuchung.veranstaltung.kuerzel']\n                      ",modifiers:{"trim":true}}],staticClass:"form-control input",attrs:{"type":"text","tabindex":3,"placeholder":" "},domProps:{"value":(
                        _vm.filters['dozentenbuchung.veranstaltung.kuerzel']
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, 'dozentenbuchung.veranstaltung.kuerzel', $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.shortdesignation")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                        _vm.filters['dozentenbuchung.veranstaltung.bezeichnung']
                      ),expression:"\n                        filters['dozentenbuchung.veranstaltung.bezeichnung']\n                      ",modifiers:{"trim":true}}],staticClass:"form-control input",attrs:{"tabindex":4,"type":"text","placeholder":" "},domProps:{"value":(
                        _vm.filters['dozentenbuchung.veranstaltung.bezeichnung']
                      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, 'dozentenbuchung.veranstaltung.bezeichnung', $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.designation")))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('single-select-person',{attrs:{"id":"dozent","person":_vm.filters.dozent,"label":_vm.$t('global.lecturer'),"allowOpen":false,"rollen":_vm.filterRollen,"tabindex":5,"editable":""},on:{"confirmed":_vm.setDozent}})],1)]),_c('filter-search-reset-button')],1)])],1)]),_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"container-fluid"},[_c('head-menu',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.$t('dashboard.presence'),"anzahl":_vm.anzahlDaten,"anzahlAusFilter":_vm.anzahlDatenFilter}}),_vm._m(0),_c('div',{staticClass:"row block belegliste"},[_c('div',{staticClass:"w-100-p"},[_c('div',[_c('b-table',{ref:"selectableTable",attrs:{"small":"","tbody-tr-class":"item","items":_vm.listData,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-icon-left":"","no-local-sorting":"","fixed":"","sort-compare":_vm.myCompare},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"sort-changed":_vm.sortingChanged,"row-dblclicked":_vm.details},scopedSlots:_vm._u([{key:"top-row",fn:function({ fields }){return _vm._l((fields),function(field){return _c('td',{key:field.key},[(
                          field.key != 'selected' && field.searchable != false
                        )?_c('b-input',{attrs:{"size":"sm","placeholder":field.label},model:{value:(_vm.filters[field.key]),callback:function ($$v) {_vm.$set(_vm.filters, field.key, $$v)},expression:"filters[field.key]"}}):_vm._e()],1)})}},{key:"cell(abgeschlossen)",fn:function(row){return [(row.item.abgeschlossen)?_c('font-awesome-icon',{staticStyle:{"color":"green"},attrs:{"icon":"fa-solid fa-check"}}):_vm._e()]}}])}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_vm._v(_vm._s(_vm.$t("global.loadmoredata")))]),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(_vm._s(_vm.$t("global.nofurtherdata")))]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("global.nofurtherdatafound"))+" ")])])],1)])])],1)])])]),_c('loading-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"block br-t-l-0 w-100-p br-t-l-xl-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"ml-4 mr-2"})])])])
}]

export { render, staticRenderFns }