<template>
  <div class="list">
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.beschreibung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.titel"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.title") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-von"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.datum_von"
                        field="datum_von"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.fromdate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-von"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.datum_bis"
                        field="datum_bis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.untildate") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button
                  @searchWithFilter="filterSearch"
                  @resetFilter="clearFilter"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="News"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <button class="btn btn-success" @click="neueNews">
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />{{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="news-loeschen-modal"
      :selectedIds="this.selectedIds"
      :multiple="true"
      @confirmed="newsLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import DatePicker from "@/components/Datepicker";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

export default {
  name: "NewsListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    FilterSearchResetButton,
    DatePicker,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + "News";
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      fields: [
        { key: "selected", label: "" },
        {
          key: "datum",
          searchable: false,
          sortable: true,
          label: this.$t("global.date"),
        },
        { key: "titel", sortable: true, label: this.$t("global.title") },
        {
          key: "beschreibung",
          sortable: true,
          label: this.$t("global.description"),
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.beschreibung = this.setQueryParam(this.filters.beschreibung);
      params.titel = this.setQueryParam(this.filters.titel);
      params.datum_von = this.setQueryParam(this.filters.datum_von);
      params.datum_bis = this.setQueryParam(this.filters.datum_bis);

      return params;
    },
  },
  created() {
    this.initFilter("news-liste", "news/", false);
  },
  mounted() {
    this.setHeaders("global", ["", "date", "title", "description"]);
  },
  methods: {
    details(news) {
      this.$router.push({
        name: "news",
        params: { id: news.id, anzeige: 0 },
      });
    },

    newsLoeschen() {
      Api.delete("news", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "news-loeschen-modal");
    },

    neueNews() {
      this.$router.push({ name: "news" });
    },
  },
};
</script>

<style>
</style>
