import Router from "vue-router";

import templateRoutes from "./routes/Template";
import einstellungenRoutes from "./routes/Einstellungen";
import subventionRoutes from "./routes/Subvention";
import mahnungRoutes from "./routes/Mahnung";
import artikelRoutes from "./routes/Artikel";
import bildungRoutes from "./routes/Bildung";
import anmeldungRoutes from "./routes/Anmeldung";
import ressourcenRoutes from "./routes/Ressourcen";
import geschaeftspartnerRoutes from "./routes/Geschaeftspartner";
import notenRoutes from "./routes/Noten";
import kommunikationRoutes from "./routes/Kommunikation";
import leitsytemRoutes from "./routes/Leitsystem";
import dozierendeRoutes from "./routes/Dozierende";
import finanzenRoutes from "./routes/Finanzen";
import crmRoutes from "./routes/CRM";
import journalRoutes from "./routes/Journal";
import produktRoutes from "./routes/Produkt";
import torRoutes from "./routes/Tor";
import newsRoutes from "./routes/News";


const baseRoutes = [
	{
		path: "/login",
		name: "login",
		component: () => import("./pages/Login"),
	},
	{
		path: "/",
		name: "Startseite",
		component: () => import("@/pages/Startseiten/Startseite"),
	},
	{
		path: "*",
		name: "ERP Startseite",
		component: () => import("@/pages/Startseiten/Startseite"),
	},
	{
		path: "/error/:errorcode",
		name: "errorpage",
		component: () => import("./pages/Errorpage"),
		props: (route) => {
			return {
				errorcode: route.params.errorcode,
			};
		},
	},
];

const routes = baseRoutes.concat(
	templateRoutes,
	einstellungenRoutes,
	subventionRoutes,
	artikelRoutes,
	bildungRoutes,
	anmeldungRoutes,
	ressourcenRoutes,
	geschaeftspartnerRoutes,
	notenRoutes,
	kommunikationRoutes,
	mahnungRoutes,
	leitsytemRoutes,
	dozierendeRoutes,
	finanzenRoutes,
	crmRoutes,
	journalRoutes,
	produktRoutes,
	torRoutes,
	newsRoutes
);

export default new Router({
	mode: "history",
	base: process.env.VUE_APP_BASE_URL,
	routes: routes,
});
