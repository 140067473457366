import {
	SET_NEWSTAGS,
} from "./mutations.type";

import {
	LADE_AUSWAHLWERTE_NEWS,
} from "./actions.type";

import Api from "../../Api";

export default {
	namespaced: true,
	state: {
		newstags: [],
	},
	mutations: {
		[SET_NEWSTAGS](state, val) {
			state.newstags = val;
		},
	},
	actions: {
		async [LADE_AUSWAHLWERTE_NEWS](context) {
			let response = await Api.get("news/auswahlwerte/");
			context.commit(SET_NEWSTAGS, response.data.newstags);
		},
	},
};
