import NewsListe from "@/pages/Dashboardlisten/Newsliste";
import News from "@/pages/News/News.vue"

export default [
	{
		path: "/Newsliste",
		name: "news-liste",
		component: NewsListe,
	},
	{
		path: "/news/:id?/",
		name: "news",
		props: (route) => {
			return {
				id: route.params.id,
			};
		},
		component: News,
	},
];
