module.exports = {
	dropzoneOptionsBild(baseURL, id) {
		return {
			url: baseURL + `/bildupload/?id=${id}`,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 4,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "Bild zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		};
	},

	dropzoneOptionsSlides(baseURL, id) {
		return {
			url: baseURL + `/slideupload/?id=${id}`,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 20,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "Bild zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		};
	},

	dropzoneOptionsFile(baseURL, id, foreignTable, foreignKey, gptyp) {
		return {
			url:
				gptyp == "firma"
					? baseURL +
					`/dokument/?firmaId=${id}&foreigntable=${foreignTable}&foreignkey=${foreignKey}`
					: baseURL +
					`/dokument/?id=${id}&foreigntable=${foreignTable}&foreignkey=${foreignKey}`,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 10,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "Datei zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		};
	},

	dropzoneOptionsAttachments(baseURL, id) {
		return {
			url: baseURL + `/mail/attachment/?id=${id}`,
			//method: "put", kann angegeben werden, default ist "post"
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 999,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "Datei zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		};
	},

	dropzoneOptionsESR(baseURL) {
		return {
			url: baseURL + `/esrfile/`,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 4,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "ESR-File zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		};
	},
	dropzoneOptionsQRR(baseURL) {
		return {
			url: baseURL + `/xml-upload/qrr/`,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 4,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "QRR-File zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		};
	},
	dropzoneOptionsExcel(url) {
		return {
			url: url,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 4,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "Excel-File zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,

			accept: function (file, done) {
				console.log(file);
				if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
					done("Error! Files of this type are not accepted");
				}
				else { done(); }
			},
		};
	},
	dropzoneOptionsNews(url) {
		return {
			url: url,
			thumbnailWidth: 150,
			thumbnailHeight: 150,
			maxFilesize: 4,
			autoProcessQueue: false,
			uploadMultiple: false,
			addRemoveLinks: true,
			dictDefaultMessage: "File zum Hochladen hier reinziehen oder klicken",
			dictRemoveFile: "x",
			withCredentials: true,
		}
	},
};
