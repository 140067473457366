import Vue from "vue";
import Vuex from "vuex";

import { uuidv4 } from "../utils/Uuid";
import "../extensions/ArrayExtension";

import permissions from "@/mock/Permissions";

import geschaeftspartner from "./geschaeftspartner";
import rechnung from "./rechnung";
import artikel from "./artikel";
import veranstaltung from "./veranstaltung";
import korrespondenz from "./korrespondenz";
import mail from "./mail";
import noten from "./noten";
import dozent from "./dozent";
import ressourcen from "./ressourcen";
import anmeldung from "./anmeldung";
import lohn from "./lohn";
import dashboard from "./dashboard";
import subvention from "./subvention";
import subventionbeitrag from "./subventionbeitrag";
import zahlungseingang from "./zahlungseingang";
import beleg from "./beleg";
import debitor from "./debitor";
import mahnstufen from "./mahnstufen";
import noteneingabe from "./noteneingabe";
import praesenz from "./praesenz";
import filter from "./filter";
import finanzen from "./finanzen";
import crm from "./crm";
import journal from "./journal";
import langtexts from "./langtexts";
import news from "./news";

import {
	SET_APPKEYS,
	SET_ARTIKELTYPEN,
	SET_ZAHLUNGSBEDINGUNGEN,
	SET_LIEFERBEDINGUNGEN,
	SET_PERMISSIONS,
	SET_BELEG_STATISTIK,
	SET_ERINNERUNGEN,
	CHANGE_ERINNERUNG,
	ADD_ERINNERUNG,
	SET_MITARBEITERLISTE,
	SET_POSITIONEN,
	CHANGE_POSITION,
	CHANGE_UNTERPOSITION,
	ADD_POSITION,
	ADD_POSITIONEN,
	SWAP_POSITIONEN,
	SET_EINHEITEN,
	SET_VERKNUEPFTE_BELEGE,
	CHANGE_VERKNUEPFTER_BELEG,
	ADD_VERKNUEPFTER_BELEG,
	SET_MEHRWERTSTEUERSAETZE,
	REMOVE_POSITIONEN,
	SET_LAENDER,
	SET_LAGER,
	SET_ARTIKELGRUPPEN,
	SET_GEBUCHT,
	SET_LIEFERANT,
	SET_MITARBEITER,
	SET_BELEGTYP,
	ADD_LIEFERANT,
	SET_LAGERPLAETZE,
	ADD_LAGERPLATZ,
	SET_BELEGTYPEN,
	SET_WINDOW_HEIGHT,
	SET_WINDOW_WIDTH,
	ADD_MEHRWERSTEUERSATZ,
	SET_ADRESSTYPEN,
	SET_FILTERSTATE,
	SET_BERECHTIGUNGEN,
	SET_GRUPPEN,
	SET_GRUPPENLISTE,
} from "./mutations.type";

import auftrag from "./auftrag";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		appkeys: [],
		//bonitaeten: [],
		artikelGruppen: [],
		belegtyp: null,
		belegtypen: [],
		einheiten: [],
		gebucht: false,
		laender: [],
		lager: [],
		lieferanten: [],
		mitarbeiterList: [],
		lieferant: null,
		mehrwertsteuersaetze: [],
		mitarbeiter: null,
		positionen: [],
		verknuepfteBelege: [],
		windowHeight: 0,
		windowWidth: 0,
		lagerplaetze: [],
		artikeltypen: null,
		zahlungsbedingungen: [],
		lieferbedingungen: [],
		permissions: permissions,
		belegstatistik: {},
		erinnerungen: [],
		adresstypen: [],
		filterState: null,
		berechtigungen: [],
		gruppen: [],
		gruppenliste: [],
	},
	mutations: {
		[SET_POSITIONEN](state, position) {
			position.forEach((p) => {
				if (!p.key) p.key = uuidv4();
			});
			state.positionen = position;
		},
		[CHANGE_POSITION](state, position) {
			let pos = state.positionen.find((p) => p.key === position.key);
			let index = state.positionen.indexOf(pos);
			state.positionen[index] = position;
		},
		[CHANGE_UNTERPOSITION](state, [oberposition, position]) {
			let pos = state.positionen.find((p) => p.key === oberposition.key);
			if (pos) {
				let indexOp = state.positionen.indexOf(pos);
				let upos = pos.unterpositionen.find((p) => p.key === position.key);
				let indexUp = pos.unterpositionen.indexOf(upos);
				state.positionen[indexOp].unterpositionen[indexUp] = position;
			}
		},
		[ADD_POSITION](state, postion) {
			postion.key = uuidv4();
			state.positionen.push(postion);
		},
		[ADD_POSITIONEN](state, positionen) {
			positionen.forEach((p) => {
				p.key = uuidv4();
				state.positionen.push(p);
			});
		},
		[SWAP_POSITIONEN](state, [index1, index2]) {
			state.positionen.swap(index1, index2);
		},
		[REMOVE_POSITIONEN](state, index) {
			state.positionen.splice(index, 1);
		},
		[SET_EINHEITEN](state, einheiten) {
			state.einheiten = einheiten;
		},
		[SET_VERKNUEPFTE_BELEGE](state, verknuepfteBelege) {
			verknuepfteBelege.forEach((b) => {
				if (!b.key) b.key = uuidv4();
			});
			state.verknuepfteBelege = verknuepfteBelege;
		},
		[CHANGE_VERKNUEPFTER_BELEG](state, [index, beleg]) {
			state.verknuepfteBelege[index] = beleg;
		},
		[ADD_VERKNUEPFTER_BELEG](state, beleg) {
			if (!beleg.key) beleg.key = uuidv4();
			state.verknuepfteBelege.push(beleg);
		},
		[SET_MEHRWERTSTEUERSAETZE](state, saetze) {
			state.mehrwertsteuersaetze = saetze;
		},
		[ADD_MEHRWERSTEUERSATZ](state, satz) {
			satz.ermaessigt = false;
			satz.gueltigAb = "";
			satz.gueltigBis = "";
			satz.keine = false;
			satz.standard = false;
			state.mehrwertsteuersaetze.push(satz);
		},
		[SET_LAENDER](state, laender) {
			state.laender = laender;
		},
		[SET_ADRESSTYPEN](state, adresstypen) {
			state.adresstypen = adresstypen;
		},
		[SET_LAGER](state, lager) {
			state.lager = lager;
		},
		[SET_ARTIKELGRUPPEN](state, gruppen) {
			state.artikelGruppen = gruppen;
		},
		[SET_GEBUCHT](state, gebucht) {
			state.gebucht = gebucht;
		},
		[SET_LIEFERANT](state, lieferant) {
			state.lieferant = lieferant;
		},
		[SET_MITARBEITER](state, mitarbeiter) {
			state.mitarbeiter = mitarbeiter;
		},
		[SET_BELEGTYP](state, belegtyp) {
			state.belegtyp = belegtyp;
		},
		[ADD_LIEFERANT](state, lieferant) {
			if (state.lieferanten.filter((l) => l.id === lieferant.id).length === 0)
				state.lieferanten.push(lieferant);
		},
		[SET_MITARBEITERLISTE](state, mitarbeiter) {
			state.mitarbeiterList = mitarbeiter;
		},
		[SET_WINDOW_HEIGHT](state, height) {
			state.windowHeight = height;
		},
		[SET_WINDOW_WIDTH](state, width) {
			state.windowWidth = width;
		},
		[SET_BELEGTYPEN](state, typen) {
			state.belegtypen = typen;
		},
		[SET_LAGERPLAETZE](state, lagerplaetze) {
			state.lagerplaetze = lagerplaetze;
		},
		[ADD_LAGERPLATZ](state, lagerplatz) {
			state.lagerplaetze.push(lagerplatz);
		},
		[SET_ARTIKELTYPEN](state, artikeltypen) {
			state.artikeltypen = artikeltypen;
		},
		[SET_ZAHLUNGSBEDINGUNGEN](state, zahlungsbedingungen) {
			state.zahlungsbedingungen = zahlungsbedingungen;
		},
		[SET_LIEFERBEDINGUNGEN](state, lieferbedingungen) {
			state.lieferbedingungen = lieferbedingungen;
		},
		[SET_PERMISSIONS](state, permissions) {
			state.permissions = permissions;
		},
		[SET_BELEG_STATISTIK](state, [key, statistic]) {
			state.belegstatistik[key] = statistic;
		},
		[SET_ERINNERUNGEN](state, val) {
			val.forEach((e) => (e.key = uuidv4()));
			state.erinnerungen = val;
		},
		[CHANGE_ERINNERUNG](state, val) {
			let erinnerung = state.erinnerungen.find((e) => e.id === val.id);
			if (erinnerung) {
				let index = state.erinnerungen.indexOf(erinnerung);
				val.key = uuidv4();
				state.erinnerungen[index] = val;
			}
		},
		[ADD_ERINNERUNG](state, val) {
			val.key = uuidv4();
			state.erinnerungen.unshift(val);
		},
		[SET_FILTERSTATE](state, val) {
			state.filterState = val;
		},
		[SET_BERECHTIGUNGEN](state, val) {
			state.berechtigungen = val;
		},
		[SET_GRUPPEN](state, val) {
			state.gruppen = val;
		},
		[SET_GRUPPENLISTE](state, val) {
			state.gruppenliste = val;
		},
		[SET_APPKEYS](state, val) {
			state.appkeys = val;
		},
	},
	modules: {
		geschaeftspartner: geschaeftspartner,
		rechnung: rechnung,
		artikel: artikel,
		veranstaltung: veranstaltung,
		korrespondenz: korrespondenz,
		mail: mail,
		noten: noten,
		dozent: dozent,
		lohn: lohn,
		ressourcen: ressourcen,
		anmeldung: anmeldung,
		dashboard: dashboard,
		subvention: subvention,
		subventionbeitrag: subventionbeitrag,
		zahlungseingang: zahlungseingang,
		auftrag: auftrag,
		beleg: beleg,
		debitor: debitor,
		mahnstufen: mahnstufen,
		noteneingabe: noteneingabe,
		praesenz: praesenz,
		filter: filter,
		finanzen: finanzen,
		crm: crm,
		journal: journal,
		langtexts: langtexts,
		news: news,
	},
	getters: {
		appkeys: (state) => state.appkeys,
		positionen: (state) => state.positionen,
		einheiten: (state) => state.einheiten,
		verknuepfteBelege: (state) => state.verknuepfteBelege,
		mehrwertsteuersaetze: (state) => state.mehrwertsteuersaetze,
		laender: (state) => state.laender,
		adresstypen: (state) => state.adresstypen,
		lager: (state) => state.lager,
		artikelGruppen: (state) => state.artikelGruppen,
		gebucht: (state) => state.gebucht,
		lieferant: (state) => state.lieferant,
		mitarbeiter: (state) => state.mitarbeiter,
		name: (state) =>
			state.mitarbeiter
				? `${state.mitarbeiter.vorname} ${state.mitarbeiter.nachname}`
				: "",
		belegtyp: (state) => state.belegtyp,
		lieferanten: (state) => state.lieferanten,
		getLieferantById: (state) => (id) =>
			state.lieferanten.filter((l) => l.id === id)[0],
		mitarbeiterList: (state) => state.mitarbeiterList,
		windowHeight: (state) => state.windowHeight,
		windowWidth: (state) => state.windowWidth,
		belegtypen: (state) => state.belegtypen,
		lagerplaetze: (state) => state.lagerplaetze,
		standardMwst: (state) =>
			state.mehrwertsteuersaetze.find((s) => s.standard === true),
		standardMwstSatz(state) {
			let standardMwst = state.mehrwertsteuersaetze.find(
				(s) => s.standard === true
			);
			return standardMwst ? standardMwst.satz : 0;
		},
		hatErinnerungen: (state) =>
			state.erinnerungen.filter((e) => !e.gelesen).length > 0,
		anzahlErinnerungen: (state) =>
			state.erinnerungen.filter((e) => !e.gelesen).length,
		filterState: (state) => state.filterState,
	},
});
