var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mr-1"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"block block-kopf"},[_c('span',{staticClass:"col primary-headline-text p-0"},[_vm._v(_vm._s(_vm.klasse.bezeichnung))]),(_vm.semesterAnzeige.endnotenReiter == false)?_c('span',{staticClass:"clickable color-block-layer",style:({
            backgroundColor: this.promotionsstruktur.semester[_vm.index].color
              ? this.promotionsstruktur.semester[_vm.index].color
              : '#deedf6',
          }),on:{"click":_vm.toggleColorPanel}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPanel),expression:"showPanel"}],ref:"colorPanel",staticStyle:{"outline":"none"},attrs:{"tabindex":"0"},on:{"focusout":_vm.toggleColorPanel}},[_c('color-panel',{on:{"change":function($event){return _vm.setColor(_vm.klasse.color)}},model:{value:(_vm.klasse.color),callback:function ($$v) {_vm.$set(_vm.klasse, "color", $$v)},expression:"klasse.color"}})],1)])]),(_vm.semesterAnzeige.endnotenReiter == false)?_c('div',[_c('div',{staticClass:"row d-flex mb-3 mx-0"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('b-collapse',{attrs:{"visible":"","id":'collapse-' + _vm.index}},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"min-height":"100%"},on:{"drag":function($event){return _vm.setDropzoneColor()},"drop":function($event){return _vm.setDefaultDropzoneColor()},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();}}},_vm._l((_vm.klasse.faecher),function(fach,fachIndex){return _c('div',{key:fachIndex,staticClass:"m-1 p-2 eingabe-panel-kopf d-flex align-items-center text-primary",style:([
                    _vm.styleDragElement,
                    {
                      backgroundColor: _vm.promotionsstruktur.semester[_vm.index]
                        .color
                        ? _vm.promotionsstruktur.semester[_vm.index].color
                        : '#deedf6',
                    },
                  ]),attrs:{"draggable":"","filter":".action-button"},on:{"dragstart":function($event){return _vm.startDrag($event, fach, 'fach')}}},[_vm._v(" "+_vm._s(fach.bezeichnungKuerzel)+" ")])}),0)]),(
                _vm.klasse.noteneingaben ? _vm.klasse.noteneingaben.length > 0 : false
              )?_c('div',{staticClass:"row my-1 d-flex align-items-center"},[_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-left":"1.5rem"}}),_c('span',{staticClass:"col text-center",staticStyle:{"color":"#f5fbff","background-color":"#b5cfdc","border":"none","outline":"none","height":"100% !important"}},[_vm._v(" "+_vm._s(_vm.$t("global.scoreentries"))+" ")]),_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-right":"1.5rem"}})]):_vm._e(),(
                _vm.klasse.noteneingaben ? _vm.klasse.noteneingaben.length > 0 : false
              )?_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"min-height":"100%"},on:{"drag":function($event){return _vm.setDropzoneColor()},"drop":function($event){return _vm.setDefaultDropzoneColor()},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();}}},_vm._l((_vm.klasse.noteneingaben),function(noteneingabe,noteneingabeIndex){return _c('div',{key:noteneingabeIndex,staticClass:"m-1 p-2 eingabe-panel-kopf d-flex align-items-center text-primary",style:([
                    _vm.styleDragElement,
                    {
                      backgroundColor: _vm.promotionsstruktur.semester[_vm.index]
                        .color
                        ? _vm.promotionsstruktur.semester[_vm.index].color
                        : '#deedf6',
                    },
                  ]),attrs:{"draggable":"","filter":".action-button"},on:{"dragstart":function($event){return _vm.startDrag($event, noteneingabe, 'noteneingabe')}}},[_vm._v(" "+_vm._s(noteneingabe.bezeichnung)+" ")])}),0)]):_vm._e()])],1),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + _vm.index),expression:"'collapse-' + index"}],staticClass:"clickable col-1 rounded-bottom d-flex justify-content-center p-1 ml-auto mr-2",staticStyle:{"background-color":"#b5cfdc"},on:{"click":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"when-open"},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-up","size":"lg"}})],1),_c('div',{staticClass:"when-closed"},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-down","size":"lg"}})],1)])])]):_vm._e(),(_vm.semesterAnzeige.endnotenReiter == true)?_c('div',[_c('div',{staticClass:"row d-flex mb-3 mx-0"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('b-collapse',{attrs:{"visible":"","id":'collapse-' + 'endnote-' + _vm.index}},[_c('div',{staticClass:"row mb-1 d-flex align-items-center"},[_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-left":"1.5rem"}}),_c('span',{staticClass:"col text-center",staticStyle:{"color":"#f5fbff","background-color":"#b5cfdc","border":"none","outline":"none","height":"100% !important"}},[_vm._v(" "+_vm._s(_vm.$t("global.ownsectiongroups"))+" ")]),_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-right":"1.5rem"}})]),_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"min-height":"100%"},on:{"drag":function($event){return _vm.setDropzoneColor()},"drop":function($event){return _vm.setDefaultDropzoneColor()},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();}}},_vm._l((_vm.promotionsstruktur
                    .semester[_vm.index].sektionsgruppen),function(sektionsgruppe,index2){return _c('div',{key:index2,staticClass:"m-1 p-2 eingabe-panel-kopf d-flex align-items-center text-primary",style:([
                    _vm.styleDragElement,
                    {
                      backgroundColor: _vm.klasse.color
                        ? _vm.klasse.color
                        : '#deedf6',
                    },
                  ]),attrs:{"draggable":"","filter":".action-button"},on:{"dragstart":function($event){return _vm.startDrag($event, sektionsgruppe, 'sektionsgruppe')}}},[_vm._v(" "+_vm._s(sektionsgruppe.bezeichnung)+" ")])}),0)]),_c('div',{staticClass:"row my-1 d-flex align-items-center"},[_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-left":"1.5rem"}}),_c('span',{staticClass:"col text-center",staticStyle:{"color":"#f5fbff","background-color":"#b5cfdc","border":"none","outline":"none","height":"100% !important"}},[_vm._v(" "+_vm._s(_vm.$t("global.subjects"))+" ")]),_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-right":"1.5rem"}})]),_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"min-height":"100%"},on:{"drag":function($event){return _vm.setDropzoneColor()},"drop":function($event){return _vm.setDefaultDropzoneColor()},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();}}},_vm._l((_vm.klasse.faecher),function(fach,fachIndex){return _c('div',{key:fachIndex,staticClass:"m-1 p-2 eingabe-panel-kopf d-flex align-items-center text-primary",style:([
                    _vm.styleDragElement,
                    {
                      backgroundColor: _vm.promotionsstruktur.semester[_vm.index]
                        .color
                        ? _vm.promotionsstruktur.semester[_vm.index].color
                        : '#deedf6',
                    },
                  ]),attrs:{"draggable":"","filter":".action-button"},on:{"dragstart":function($event){return _vm.startDrag($event, fach, 'fach')}}},[_vm._v(" "+_vm._s(fach.bezeichnungKuerzel)+" ")])}),0)]),(
                _vm.klasse.noteneingaben ? _vm.klasse.noteneingaben.length > 0 : false
              )?_c('div',{staticClass:"row my-1 d-flex align-items-center"},[_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-left":"1.5rem"}}),_c('span',{staticClass:"col text-center",staticStyle:{"color":"#f5fbff","background-color":"#b5cfdc","border":"none","outline":"none","height":"100% !important"}},[_vm._v(" "+_vm._s(_vm.$t("global.scoreentries"))+" ")]),_c('hr',{staticClass:"col",staticStyle:{"border-top":"1px solid #f5fbff","margin-right":"1.5rem"}})]):_vm._e(),(
                _vm.klasse.noteneingaben ? _vm.klasse.noteneingaben.length > 0 : false
              )?_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"min-height":"100%"},on:{"drag":function($event){return _vm.setDropzoneColor()},"drop":function($event){return _vm.setDefaultDropzoneColor()},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();}}},_vm._l((_vm.klasse.noteneingaben),function(noteneingabe,noteneingabeIndex){return _c('div',{key:noteneingabeIndex,staticClass:"m-1 p-2 eingabe-panel-kopf d-flex align-items-center text-primary",style:([
                    _vm.styleDragElement,
                    {
                      backgroundColor: _vm.promotionsstruktur.semester[_vm.index]
                        .color
                        ? _vm.promotionsstruktur.semester[_vm.index].color
                        : '#deedf6',
                    },
                  ]),attrs:{"draggable":"","filter":".action-button"},on:{"dragstart":function($event){return _vm.startDrag($event, noteneingabe, 'noteneingabe')}}},[_vm._v(" "+_vm._s(noteneingabe.bezeichnung)+" ")])}),0)]):_vm._e()])],1),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + 'endnote-' + _vm.index),expression:"'collapse-' + 'endnote-' + index"}],staticClass:"clickable col-1 rounded-bottom d-flex justify-content-center p-1 ml-auto mr-2",staticStyle:{"background-color":"#b5cfdc"},on:{"click":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"when-open"},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-up","size":"lg"}})],1),_c('div',{staticClass:"when-closed"},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-down","size":"lg"}})],1)])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }