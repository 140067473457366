var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('notifications'),_c('navbar',{attrs:{"title":_vm.$t('global.batixsoftware')}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"container-fluid"},[_c('head-menu-filter',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.$t('global.filter'),"showIcons":false}}),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"options":_vm.bildschirmListe,"reduce":(s) => s.eventId,"label":"title","placeholder":_vm.$t('global.screen')},model:{value:(_vm.filterObject.bildschirm),callback:function ($$v) {_vm.$set(_vm.filterObject, "bildschirm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filterObject.bildschirm"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.nostatusfound")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.screen")))])],1)])])])])],1)]),_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"container-fluid"},[_c('head-menu',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.$t('global.calendar')}}),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"block br-t-l-0 w-100-p br-t-l-xl-0"},[_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"ml-4"},[(_vm.berechtigungen.m_ressourcen.create)?_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{ name: 'screenevent', params: { anzeige: 0 } }}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-plus"}}),_vm._v(" "+_vm._s(_vm.$t("global.new"))+" ")],1):_vm._e()],1),_c('div',{staticClass:"ml-2"},[(_vm.berechtigungen.m_ressourcen.create)?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                      name: 'standardevent',
                      params: { id: '17E23DA39AC', anzeige: 0 },
                    }}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-duotone fa-calendar-lines-pen"}}),_vm._v(" "+_vm._s(_vm.$t("global.standardevent"))+" ")],1):_vm._e()],1)])])]),_c('div',{staticClass:"row block belegliste"},[_c('div',{staticClass:"w-100-p"},[_c('div',{staticStyle:{"height":"80vh"}},[_c('calendar-view',{staticClass:"theme-default holiday-us-traditional holiday-us-official",attrs:{"show-date":_vm.showDate,"items":_vm.items,"showTimes":"","timeFormatOptions":{
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                  }},on:{"click-item":_vm.terminOeffnen},scopedSlots:_vm._u([{key:"header",fn:function(t){return _c('calendar-view-header',{attrs:{"header-props":t.headerProps},on:{"input":_vm.setShowDate}})}}])})],1)])])],1)])])]),_c('loading-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }